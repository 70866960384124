import scrollama from "scrollama";

if (document.querySelector('.scroll-tabs')) {
    const scroller = scrollama();

    const tabLinks = document.querySelectorAll('.tab-links li');
    const figures = document.querySelectorAll('.scroll-tabs .figure-slider figure');

    scroller
        .setup({
            step: ".step",
            offset: 0.8,
            debug: false,
            progress: false
        })
        .onStepEnter((response) => {
            tabLinks[response.index].classList.add('active');
            if (figures[response.index]) {
                figures[response.index].classList.add('active');
            }
        })
        .onStepExit((response) => {
            tabLinks[response.index].classList.remove('active');
            if (response.index > 0) {
                if (figures[response.index]) {
                    figures[response.index].classList.remove('active');
                }
            }
        });
}
