import './scss/styles.scss';


import './js/aos';


import './js/lazyload';
import './js/main';
import './js/app';
import './js/search';
import './js/parallax';

//import './js/lib/swiper';
import './js/slider';

//import './js/package-scroller';
import './js/scrolling-video';
//import './js/library-scroll-video';
import './js/cookieconsent-init';


import './js/product';
import './js/product-picker';
import './js/scrolling-tabs';