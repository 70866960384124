const productPicker = (function ($) {

    let pickerForm = document.getElementById('productPicker');
    let currentStep = 0;
    let tabs = document.getElementsByClassName("tab");
    let selectedProducts = [];
    let selectedCalories = 0;
    let selectedPrice = 0;

    let calculateButton = $('.navigation #calculate');
    let prevButton = document.getElementById('prevBtn');
    let nextButton = document.getElementById('nextBtn');
    let addCartButton = $('.navigation .add-to-cart');
    let addToCartBtn = document.querySelector('.calories-bar .add-to-cart');

    const setupEventListeners = function() {
        // steps bar
        $('.stepnr').on('click', function() {
            let stepNr = $(this).data('step');
            let stepAmount = stepNr - currentStep;
            if (currentStep > stepNr) {
                goToStep(stepAmount);
            }
        })
        $('.back').on('click', function() {
            if (currentStep > 0) {
                goToStep(-1);
            }
        })
        // Navigation
        $('.navigation button.step').on('click', function() {
            goToStep($(this).data('step'));
        })

        $('.intro-text button').on('click', toggleText);

        $(document).on('click', '#productPicker .calculate', calculate);

        // calories bar btn
        addToCartBtn.addEventListener('click', addToCart);

        // navigation btn
        addCartButton.on('click', function(e) {
           e.preventDefault();
            addToCartBtn.click();
        });

        // tooltips
        $(document).on('click', '.has-tooltips .check-field input', function() {
            $(this).closest('.tab').find('.tooltip').removeClass('active');
            document.getElementById($(this).prop('id') + '_tip').classList.add('active');
        });

        $('.calories-bar .tooltip-open').on('click', function() {
            $(' + .tooltip__content', $(this)).fadeToggle();
        })


        $(document).on('click', '.add-product', function() {
            //$(this).hide();
            let productsSection = $('section.products');
            productsSection.slideDown();
            let selectedCategory = $(this).data('cat');
            let selectedSection = productsSection.find('#select_' + selectedCategory);
            /*window.scrollTo({
                top: selectedSection.offset().top - 100,
                left: 0,
                behavior: 'smooth'
            });*/
        });

        $('.check-field.dietary-choices .reset').on('click', function() {
            $(this).closest('.check-field').find('input').prop('checked', false);
            $(this).addClass('active');
        })

        $('.check-field.dietary-choices input').on('change', function() {
            $('.check-field.dietary-choices .reset').removeClass('active');
        })

        $(document).on('click', 'section.products .add', selectProduct);

        $(document).on('click', 'section.selected-products .remove',function() {
            let selectedProductId = $(this).data('id');
            selectedProducts = selectedProducts.filter(selectedProduct => selectedProduct.id !== selectedProductId);
            $('section.products .product[data-id="'+ selectedProductId +'"]').removeClass('selected');
            setSelectedProducts();
        })

        $(document).on('change', '.quantity .qty',function() {
            let qty = $(this).val();
            if (qty > 0) {
                let selectedProductId = $(this).closest('.product').data('product');
                let idx = selectedProducts.findIndex(obj => obj.id === selectedProductId);
                selectedProducts[idx].qty = qty;
                calculateTotalCalories();
                calculateTotalPrice();
            }
        })

        $(document).on('click', '.calories_selection', function() {
            let selectedProduct = $(this).closest('.product');
            let selectedProductId = selectedProduct.data('product');
            let selectedCalories = $(this).data('calories');

            selectedProduct.data('calories', selectedCalories);

            selectedProduct.data('variation', parseInt($(this).val()));
            selectedProduct.find('.product-cal').text(selectedCalories + 'kcal');

            //let radioFieldId = $(this).id;
            //$('#' + radioFieldId).prop('checked', true).change();


            let idx = selectedProducts.findIndex(obj => obj.id === selectedProductId);
            if (idx >= 0) {
                selectedProducts[idx].calories = parseInt(selectedCalories);
                calculateTotalCalories();
                calculateTotalPrice();
            }

        })
    }

    const toggleText = function () {
        $(this).hide();
        $('.intro-text span').fadeIn();
    }

    const validateStep = function() {

        let validateStep = '#productPicker .tab:nth-of-type(' + (currentStep + 1) + ')';
        var requiredFields = document.querySelectorAll(validateStep + ' .required input,' + validateStep + ' .required select');
        if (requiredFields) {
            var valid = Array.from(requiredFields).filter(item => item.value.length >= 1);
            if (requiredFields.length !== valid.length ) {
                alert('Please fill all required fields');
                return false;
            }
        }

        return true;

    }

    const goToStep = function(step) {
        if (!validateStep()) return false;


        //if (n == 1 && !validateForm()) return false;

        tabs[currentStep].style.display = "none";

        currentStep = currentStep + step;
        // if you have reached the end of the form...
        /*if (currentStep >= 3) {
            // ... the form gets submitted:
            //document.getElementById("regForm").submit();
            //return false;
        } */

        showStep(currentStep);
        $('.steps .stepnr').removeClass('active');
        $('.steps .stepnr:nth-of-type(-n + ' + currentStep + ')').addClass('active');

        if (currentStep > 0) {
            $('.picker-header').show();
        } else {
            $('.picker-header').hide();
        }
    }

    const showStep = function(step) {
        showProgress();

        tabs[step].style.display = "block";

        if (step === 0 || step > 5) {
            prevButton.style.display = "none";
        } else {
            prevButton.style.display = "inline-block";
        }
        if (step === (tabs.length - 1) || step >= 4) {
            nextButton.style.display = 'none';
        } else {
            nextButton.style.display = 'inline-block';
        }
        if (step === 4) {
            calculateButton.show();
        } else {
            calculateButton.hide();
        }
        if (step === 5) {
            addCartButton.show();
        } else {
            addCartButton.hide();
        }
        /*if (step === 3) {
            document.getElementById("nextBtn").classList.remove('step');
        }

        if (step === 4) {
            document.getElementById("nextBtn").innerHTML = "Calculate";

            document.getElementById("nextBtn").classList.remove('btn-light');
            document.getElementById("nextBtn").classList.add('calculate');
            document.getElementById("nextBtn").classList.add('btn-secondary');
        } else {
            document.getElementById("nextBtn").innerHTML = "Next";
        }*/
        //console.log(step, tabs.length);
        /*if (n == (x.length - 1)) {
            document.getElementById("nextBtn").innerHTML = "Submit";
        } else {
            document.getElementById("nextBtn").innerHTML = "Next";
        }*/

    }

    const showProgress = function() {
        let percent = (currentStep/(tabs.length-1) * 100).toFixed(0);
        document.querySelector('.percent').innerText = percent;
        document.querySelector('.progress .bar').style.width = percent + '%';
    }

    const selectProduct = function() {
        let product = $(this).closest('.product');
        setProduct(product, 1);

    }

    const setProduct = function(product, qty) {
        product.addClass('selected');
        let productData = {
            id : product.data('id'),
            qty: parseInt(qty),
            calories: product.data('calories') ? product.data('calories') : 0,
            category: product.data('category'),
            price: product.data('price') ? product.data('price') : 0,
            variation: product.data('variation') ? product.data('variation') : ''
        };
        selectedProducts.push(productData);
        
        setSelectedProducts();
    }

    const calculateTotalCalories = function()
    {
        selectedCalories = selectedProducts.reduce((total, obj) =>
            (obj.calories * obj.qty) + total
            , 0);
        document.getElementById('selectedCalories').innerText = selectedCalories;
    }

    const calculateTotalPrice = function()
    {
        selectedPrice = selectedProducts.reduce((total, obj) =>
                (obj.price * obj.qty) + total
            , 0);
        document.getElementById('selectedPrice').innerText = selectedPrice.toFixed(2);
    }

    const setSelectedProducts = function()
    {

        addToCartBtn.disabled = (selectedProducts.length < 1);
        addCartButton.disabled = (selectedProducts.length < 1);
        let categories = document.querySelectorAll('.selected-products .category');
        let categoryHTML = [];
        categories.forEach((category, idx) => {
            categoryHTML[category.dataset.cat] = '';
        })

        selectedProducts.forEach((product, idx) => {
            let productCat = product.category;
            let productTemplate = $('section.products .product[data-id="' + product.id + '"] .product-template').html();
            categoryHTML[productCat] += '<div class="product" data-product="'+product.id+'">' + productTemplate + '</div>';
        })

        categories.forEach((category, idx) => {
            console.log(category.dataset.cat);
            categoryHTML[category.dataset.cat] += '<a class="add-product" href="#select_'+category.dataset.cat+'">add new product</a>';
        })

        Object.keys(categoryHTML).forEach((category) => {
            $('.selected-products [data-cat="' + category + '"]').html(categoryHTML[category]);
        })


        /*let html = '';
        selectedProducts.forEach((product, idx) => {
            let productTemplate = $('section.products .product[data-id="' + product.id + '"] .product-template').html();
            html += '<div class="product" data-product="'+product.id+'">' + productTemplate + '</div>';
        })

        $('section.selected-products').html(html);*/

        selectedProducts.forEach((product, idx) => {
            let selectionProduct =  $('section.selected-products .product[data-product="' + product.id + '"]');
            selectionProduct.find('.qty')
                .val(product.qty)
                .change();
            if (product.variation) {
                selectionProduct.find('#variation_' + product.variation + '_calories')
                    .prop('checked', true);
            }

        })

        calculateTotalCalories();
        calculateTotalPrice();
    }
    /*
    var requiredFields = document.querySelectorAll('.order-details input:required');
           var valid = Array.from(requiredFields).filter(item => item.value.length > 1);
           if (requiredFields.length != valid.length ) {
               alert('Please fill all required fields');
               e.preventDefault();
               return false;
           }
    */
    const addToCart = function() {
        /*let toSend = {};
        for(let key in selectedProducts) {
            toSend[selectedProducts[key].id] = selectedProducts[key];
        }
        let requestData = {
            action: 'add_products_to_cart',
            products: toSend
        } */

        $(this).text('Loading...');

        $.ajax({
            url: woocommerce_params.ajax_url,
            type: "POST",
            data: {
                action: 'add_products_to_cart',
                products: selectedProducts
            },
            processData: true,
            dataType: "json",
            success: function(response){
                //alert(response.content);
                //console.log('current', currentStep);
                goToStep(1);
            },
            error: function(xhr, textStatus, error){
                // Handle error response
            }
        });
    }

    const calculate = function () {

        let formData = new FormData(pickerForm);
        formData.append('action', 'calculate_calories');
        selectedProducts = [];
        let that = $(this);
        let btnText = that.text();
        that.prop('disabled', true);
        that.text('calculating...');
        $.ajax({
            url: woocommerce_params.ajax_url,
            type: "POST",
            data: formData,
            processData: false,
            contentType: false,
            success: function(response){
                let res = JSON.parse(response);
                document.getElementById('calories').innerText = res.result;
                $('section.products').html(res.productsHTML);

                res.products.forEach((productId, idx) => {
                    let selProduct = $('section.products .product[data-id="'+productId+'"]');
                    setProduct(selProduct, formData.get('days'));
                });
                that.prop('disabled', false);
                that.text(btnText);
                goToStep(1);

            },
            error: function(xhr, textStatus, error){
                // Handle error response
                that.prop('disabled', false);
                that.text(btnText);
            }

        });
        ['age', 'gender', 'weight', 'height', 'intensity'].forEach((field) => {
            $('.selected_' + field).text(formData.get(field));
        });
        let days = formData.get('days') ? formData.get('days') : 1;
        let hours = formData.get('hours') ? formData.get('hours') : 1;

        $('.selected_duration').text((days * hours) + 'h');
    }

    return {
        init: function () {
            setupEventListeners();
            showStep(0);
        }
    }
})(jQuery);

window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('main.product-picker')) {
        productPicker.init();
    }
})