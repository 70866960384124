import scrollama from "scrollama";

/*if (document.querySelector('.scrolly-video-wrapper')) {
    const vScroller = scrollama();

    const steps = document.querySelector('.scrolly-video-wrapper .texts');
    vScroller
        .setup({
            step: ".text",
            offset: 0,
            debug: true,
            progress: true
        })
        .onStepProgress((response) => {
            let progress = response.progress;
            console.log(response.index);
        })
        .onStepEnter((response) => {

        })
        .onStepExit((response) => {

        });
}*/
if (document.querySelector('.animatev')) {

    /*var animFrame = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        // IE Fallback, you can even fallback to onscroll
        function (callback) { window.setTimeout(callback, 1000 / 60) };

    */
    const vScroller = scrollama();
    const vid = document.querySelector('.animatev video');
    const items = document.querySelectorAll('.animatev .item-content');

    function handleResize() {
        vScroller.resize();
    }
    let videoDuration = vid.dataset.duration;
    let time = 0;
    let lastPosition;

    function init() {
        handleResize();
        vScroller
            .setup({
                step: ".animatev article .item",
                offset: 0.9,
                debug: false,
                progress: true
            })
            .onStepProgress((response) => {
                let progress = response.progress;
                time = progress.toFixed(2);
                items.forEach((item) => {
                    item.classList.remove('active');
                })
                if (time > 0 && time < 0.3) {
                    items[0].classList.add('active');
                }
                if (time > 0.3 && time < 0.5) {
                    items[1].classList.add('active');
                }
                if (time > 0.5 && time < 0.7) {
                    items[2].classList.add('active');
                }
                if (time > 0.6 && time < 0.95) {
                    items[3].classList.add('active');
                }
            })
            .onStepEnter((response) => {
                setInterval(() => {
                    if (lastPosition === time) return;
                    requestAnimationFrame(() => {
                        vid.currentTime = videoDuration * time;
                        vid.pause();
                        lastPosition = time;
                    });
                }, 50);
            })
            .onStepExit((response) => {

            });
    }
    init();

    window.addEventListener('resize', function() {
        if (window.innerWidth > 1200) {
            handleResize();
        }
    } );
}
