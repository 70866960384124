import AOS from 'aos';
import 'aos/dist/aos.css';

/*let blockColumns = document.querySelectorAll('.wp-block-column, .cover-responsive-block__inner');
if (blockColumns) {
    blockColumns.forEach((block) => {
        block.setAttribute("data-aos", "fade-up");
        block.setAttribute("data-aos-duration", "800");
    })
}*/

document.addEventListener("DOMContentLoaded", function() {
    AOS.init({
        once: true,
        startEvent: 'DOMContentLoaded'
    });
});
