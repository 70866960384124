var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'en',
    auto_language: 'document',
    autoclear_cookies: true,
    page_scripts: true,

    languages: {
        'en': {
            consent_modal: {
                title: '',
                description: 'We are using cookies to give you the best experience on our website. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Domain'},
                    {col2: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'We are using cookies to give you the best experience on our website. You can find out more about which cookies we are using in <a href="https://tacticalfoodpack.com/privacy-policy/" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Analytics cookies',
                        description: 'Analytics cookies',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Analytics',
                                col2: 'google.com'
                            },
                        ]
                    },
                    {
                        title: 'Targeting & Advertising cookies',
                        description: 'Targeting & Advertising cookies',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Hotjar',
                                col2: 'hotjar.com'
                            },
                            {
                                col1: 'Mouseflow',
                                col2: 'mouseflow.com'
                            },
                        ]
                    }
                ]
            }
        },
        'fr-FR': {
            consent_modal: {
                title: '',
                description: 'Nous utilisons des cookies pour vous offrir la meilleure expérience sur notre site Web. <button type="button" data-cc="c-settings" class="cc-link">Laisse-moi choisir</button>',
                primary_btn: {
                    text: 'Accepter tout',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Rejeter tout',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Préférences en matière de cookies',
                save_settings_btn: 'Enregistrer les paramètres',
                accept_all_btn: 'Accepter tout',
                reject_all_btn: 'Rejeter tout',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    {col1: 'Domaine'},
                    {col2: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Utilisation des cookies',
                        description: 'Nous utilisons des cookies pour vous offrir la meilleure expérience sur notre site Web. Vous pouvez en savoir plus sur les cookies que nous utilisons dans <a href="https://tacticalfoodpack.com/fr/privacy-policy/" class="cc-link">politique de confidentialité</a>.'
                    }, {
                        title: 'Cookies strictement nécessaires',
                        description: 'Ces cookies sont essentiels au bon fonctionnement de mon site web. Sans ces cookies, le site ne fonctionnerait pas correctement.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Cookies d\'analyse',
                        description: 'Cookies d\'analyse',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Analytics',
                                col2: 'google.com'
                            },
                        ]
                    },
                    {
                        title: 'Cookies de ciblage et de publicité',
                        description: 'Cookies de ciblage et de publicité',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Hotjar',
                                col2: 'hotjar.com'
                            },
                            {
                                col1: 'Mouseflow',
                                col2: 'mouseflow.com'
                            },
                        ]
                    }
                ]
            }
        },
        'de-DE': {
            consent_modal: {
                title: '',
                description: 'Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer Website zu bieten. <button type="button" data-cc="c-settings" class="cc-link">Lass mich aussuchen</button>',
                primary_btn: {
                    text: 'Akzeptiere alle',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Alles ablehnen',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie-Einstellungen',
                save_settings_btn: 'Einstellungen speichern',
                accept_all_btn: 'Akzeptiere alle',
                reject_all_btn: 'Alles ablehnen',
                close_btn_label: 'Schließen',
                cookie_table_headers: [
                    {col1: 'Domain'},
                    {col2: 'Beschreibung'}
                ],
                blocks: [
                    {
                        title: 'Verwendung von Cookies',
                        description: 'Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer Website zu bieten. Hier erfahren Sie mehr darüber, welche Cookies wir verwenden <a href="https://tacticalfoodpack.com/de/privacy-policy/" class="cc-link">Datenschutzrichtlinie</a>.'
                    }, {
                        title: 'Unbedingt notwendige Cookies',
                        description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht ordnungsgemäß funktionieren',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Analyse-Cookies',
                        description: 'Analyse-Cookies',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Analytics',
                                col2: 'google.com'
                            },
                        ]
                    },
                    {
                        title: 'Targeting- und Werbe-Cookies',
                        description: 'Targeting- und Werbe-Cookies',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Hotjar',
                                col2: 'hotjar.com'
                            },
                            {
                                col1: 'Mouseflow',
                                col2: 'mouseflow.com'
                            },
                        ]
                    }
                ]
            }
        },
        'et': {
            consent_modal: {
                title: '',
                description: 'See veebileht kasutab küpsiseid. Vaata lähemalt <button type="button" data-cc="c-settings" class="cc-link">küpsiste seadeid</button>',
                primary_btn: {
                    text: 'Nõustu kõigiga',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Keeldu',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Küpsiste sätted',
                save_settings_btn: 'Salvesta',
                accept_all_btn: 'Nõustu kõigiga',
                reject_all_btn: 'Keeldu',
                close_btn_label: 'Sulge',
                cookie_table_headers: [
                    {col1: 'Domeen'},
                    {col2: 'Kirjeldus'}
                ],
                blocks: [
                    {
                        title: 'Küpsiste kasutus',
                        description: 'See veebileht kasutab küpsiseid. Vaata lähemalt meie <a href="https://tacticalfoodpack.com/et/privaatsuspoliitika/" target="_blank" class="cc-link">privaatsuspoliitikat</a>.'
                    }, {
                        title: 'Hädavajalikud küpsised',
                        description: 'Need küpsised on veebilehe nõuetekohaseks toimimiseks hädavajalikud. Ilma nendete ei pruugi veebileht korralikult toimida.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Analüütika küpsised',
                        description: 'Analüütika küpsised',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Analytics',
                                col2: 'google.com'
                            },
                        ]
                    },
                    {
                        title: 'Sihitud & reklaami küpsised',
                        description: 'Sihitud & reklaami küpsised',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Hotjar',
                                col2: 'hotjar.com'
                            },
                            {
                                col1: 'Mouseflow',
                                col2: 'mouseflow.com'
                            },
                        ]
                    }
                ]
            }
        }
    }
});
